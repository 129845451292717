import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 15 15"
    {...props}
  >
    <path
      fill="rgb(183, 183, 183"
      fillRule="evenodd"
      d="M7.07.65a.85.85 0 00-.828.662l-.238 1.05c-.38.11-.74.262-1.08.448l-.91-.574a.85.85 0 00-1.055.118l-.606.606a.85.85 0 00-.118 1.054l.574.912c-.186.338-.337.7-.447 1.079l-1.05.238a.85.85 0 00-.662.829v.857a.85.85 0 00.662.829l1.05.238c.11.379.261.74.448 1.08l-.575.91a.85.85 0 00.118 1.055l.607.606a.85.85 0 001.054.118l.911-.574c.339.186.7.337 1.079.447l.238 1.05a.85.85 0 00.829.662h.857a.85.85 0 00.829-.662l.238-1.05c.38-.11.74-.26 1.08-.447l.911.574a.85.85 0 001.054-.118l.606-.606a.85.85 0 00.118-1.054l-.574-.911c.187-.34.338-.7.448-1.08l1.05-.238a.85.85 0 00.662-.829v-.857a.85.85 0 00-.662-.83l-1.05-.237c-.11-.38-.26-.74-.447-1.08l.574-.91a.85.85 0 00-.118-1.055l-.606-.606a.85.85 0 00-1.055-.118l-.91.574a5.323 5.323 0 00-1.08-.448l-.239-1.05A.85.85 0 007.928.65h-.857zM4.92 3.813a4.476 4.476 0 011.795-.745L7.071 1.5h.857l.356 1.568c.659.116 1.268.375 1.795.744l1.36-.857.607.606-.858 1.36c.37.527.628 1.136.744 1.795l1.568.356v.857l-1.568.355a4.475 4.475 0 01-.744 1.796l.857 1.36-.606.606-1.36-.857a4.476 4.476 0 01-1.795.743L7.928 13.5h-.857l-.356-1.568a4.475 4.475 0 01-1.794-.744l-1.36.858-.607-.606.858-1.36a4.476 4.476 0 01-.744-1.796L1.5 7.93v-.857l1.568-.356a4.476 4.476 0 01.744-1.794L2.954 3.56l.606-.606 1.36.858zM9.026 7.5a1.525 1.525 0 11-3.05 0 1.525 1.525 0 013.05 0zm.9 0a2.425 2.425 0 11-4.85 0 2.425 2.425 0 014.85 0z"
      clipRule="evenodd"
    ></path>
  </Svg>
  );
};

export default Icon;
